import React, {useState} from "react";
import { Auth } from "aws-amplify";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {
  Box, Grid,Container
} from '@awsui/components-react';

const Login = (props) => {
  let location = useLocation()
  let navigate = useNavigate();
  let params = useParams();

  React.useEffect(() => {

    let userAuthenticated = false;
      Auth.currentAuthenticatedUser().then(user=>{
        console.log("GO")
        userAuthenticated = true;
        if(userAuthenticated){
          props.userHasAuthenticated(true);

          if(location.pathname !== '/login'){
            navigate(location.pathname);
          } else {
            navigate('/');
          }

        }        
      },error=>{
        console.log("NO GO")
        Auth.federatedSignIn();
      })
  })

  return (
    <Grid
    gridDefinition={[
      { colspan: { default: 12, xxs: 6 }, offset: { xxs: 3 } }
    ]}
  >
    <Box margin="xxl" padding="xxl">
      <Container>
        Redirecting to login screen
      </Container>
    </Box>
    </Grid>

  )

}

export default Login