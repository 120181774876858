import React, {useEffect, useCallback, useState} from 'react';
import User from "../actions/user";
import { Auth } from "aws-amplify";
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import { Typography, LinearProgress } from '@mui/material';
import {parsePUTResponseErrors} from "../resources/recordFunctions";
import {
  StatusIndicator,
  Button,
  Modal,
  Box,
  SpaceBetween,
  Container,
  Header,
  ColumnLayout,
  Badge,
  ExpandableSection
} from '@awsui/components-react';
import { createTheme} from '@material-ui/core'

import {
  MuiThemeProvider
} from "@material-ui/core/styles";
import {
  getColumns,
  getRows
} from './UserTableRAGStatusConfig.jsx';

const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#5A5A5A',
    }
  },
  typography: {
    fontSize: 15
  },
  
});

const UserTableRAGStatus = (props) => {
  //Data items for viewer and table.
  const [allData, setAllData] = useState([])

  const [rows, setRows] = useState([])
  const [columns, setColumns] = useState([])
  const [isLoading, setLoading] = useState(true)
  const [isChanged, setIsChanged] = useState(false)
  const [changedData, setChangedData] = useState({})
  const [spinner, setSpinner] = useState(false)

  // Loads initial data from Migration Tracker
  useEffect(() => {
    const fetchData = async () => {
      const session = await Auth.currentSession();
      const apiUser = new User(session);
      const appData = await apiUser.getApps();
      const serverData = await apiUser.getServers();
      const waveData = await apiUser.getWaves();
      setAllData({
        appData: appData,
        serverData: serverData,
        waveData: waveData
      })
      setColumns(getColumns())
    }
    fetchData()
}, [])

// Loads row data specific to the RAG Table
useEffect(() => {
  const fetchData = async () => {
    var data = await getRows(allData)
    setRows(data)

  }
  fetchData()
  setLoading(false)

},[allData])

function handleNotification(notification)
{
  return props.updateNotification('add', notification)
}

// Header buttons, e.g. Clear Changes Button + Save button
async function clearChanges() {
  setSpinner(true)
  const session = await Auth.currentSession();
  const apiUser = new User(session);
  const appData = await apiUser.getApps();
  const serverData = await apiUser.getServers();
  const waveData = await apiUser.getWaves();
  setAllData({
    appData: appData,
    serverData: serverData,
    waveData: waveData
  })
  setChangedData({})
  setIsChanged(false)
  setSpinner(false)
  setClearVisible(false)
}

async function handleSave() {
  setSpinner(true)
  try {
    for (var editedItem in changedData) {
      var split_group_app_id = changedData[editedItem].app_id.replace(/ /g,'').split(',')
      for (var app_id_index in split_group_app_id) {
        let newApp = Object.assign({}, changedData[editedItem]);
        let app_id = split_group_app_id[app_id_index];
        let app_name = newApp.app_name;
        const session = await Auth.currentSession();
        const apiUser = new User(session);
        if (newApp !== null) {
          delete newApp.app_id;
          delete newApp.app_name;
          var result = await apiUser.putItem(app_id, newApp, 'application');
        }
      }
      
    }
  clearChanges()
  setSpinner(false)
  handleClose()
  handleNotification({
              type: 'success',
              dismissible: true,
              header: "Updated RAG Table",
              content: "Edit updated successfully.",
            });
  } catch (e) {
    console.debug("RAG Update errors");
    console.debug(result['errors']);
    let errorsReturned = parsePUTResponseErrors(result['errors']);
    handleNotification({
      type: 'error',
      dismissible: true,
      header: "Some or all elements failed to update",
      content: (errorsReturned)
    });
    setSpinner(false)
  }

  }


// All states for Modals 
const [clearVisible, setClearVisible] = React.useState(false);

const handleClickClearOpen = () => {
  setClearVisible(true);
};

const handleClearClose = () => {
  setClearVisible(false);
};

const [visible, setVisible] = React.useState(false);

const handleClickOpen = () => {
  setVisible(true);
};

const handleClose = () => {
  setVisible(false);
};

const handleProcessRowUpdateError = React.useCallback((error) => {
    }, []);

// When a row is edited, processRowUpdate is called
const processRowUpdate = React.useCallback((newRow, oldRow) => {
    const updatedRow = { ...newRow, isNew: false };
    var changedRow = {}
    let id = oldRow['id']
    for (var i in oldRow) {
      if (oldRow[i] != newRow[i] ){
        if (i == 'rag_comments') {
          changedRow['rag_comments'] = newRow[i]
        } else {
          changedRow[i] = newRow[i]
        }
      }
    }
    changedRow['app_id'] = newRow['app_id']
    changedRow['app_name'] = newRow['app_name']
    var newData = changedData
    if (id in newData) {
      newData[id] = Object.assign({}, newData[id], changedRow);
    } else {
      newData[id] = changedRow
    }
    // If any changes, call setIsChanged and only update rows that have been changed
    var tempData = newData
    for (var changes in tempData) {
      var idx = allData['appData'].findIndex(app => app.app_id == changes)
      for(var value in tempData[changes]) {
        if (Object.keys(newData[changes]).length === 2) {
          delete newData[changes]
          break
        }
        if (value === 'app_id' || value === 'app_name') continue
        if (tempData[changes][value] === allData['appData'][idx][value]) {
          delete newData[changes][value]
        }
      }
     
    }
    setChangedData(newData)
    setIsChanged(Object.keys(newData).length === 0 ? false : true)    

    return updatedRow;
});

  function cleanText(text) {
    return text.replace('_' , ' ').replace('rag', 'RAG')
  }

  return (
    <MuiThemeProvider theme={theme}>
          {
      isLoading
      ? <StatusIndicator type="loading">
        Loading schema...
      </StatusIndicator>
      :
    <div style={{ "height": 800, width: '100%' }}>
      <Header
      variant="h2"
      // counter={counter ? counter : undefined}
      description=
      {<><Typography variant='h5'>Table for Migration Factory to edit RAG Status of applications.
          <br></br>
        </Typography>
        <Typography variant='h5' color="red">
        *Only RAG Status and RAG Comments can be edited. Double click a cell to edit - Make sure to REVIEW & SAVE changes
        </Typography>
        </>
      }
      actions= {
        <SpaceBetween direction="horizontal" size="s">

        <Button onClick={handleClickClearOpen} disabled={!isChanged} >
         Clear Changes
        </Button>
        <Button onClick={handleClickOpen} disabled={!isChanged} variant="primary">
        Review & Save
       </Button>
       </SpaceBetween>
      }    
    >
      RAG Status Table
      </Header>
      <DataGrid
        // autoHeight={true}
        rows={rows}
        getRowHeight={() => 'auto'}
        columns={columns}
        
        components={{
          Toolbar: GridToolbar,
          LoadingOverlay: LinearProgress,
        }}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            textOverflow: "clip",
            whiteSpace: "break-spaces",
            lineHeight: 1
        },
        '& .MuiDataGrid-cell': {
          whiteSpace: 'normal',
          wordWrap: 'breakWord',
          border: 1,
        },
          boxShadow: 2,
          borderColor: 'primary.light',
          whiteSpace: 'pre',
          '& .super-app-theme--cell': {
            backgroundColor: 'rgba(224, 183, 60, 0.55)',
            color: '#1a3e72',
          },
          '& .super-app.green': {
            backgroundColor: 'rgba(157, 255, 118, 0.49)',
          },
          '& .super-app.red': {
            backgroundColor: '#d47483',
          },
          '& .super-app.amber': {
            backgroundColor: '#ffbf00',
          },
          '& .super-app.completed': {
            backgroundColor: '#ADD8E6',
           
          },
          '& .super-app.disabled': {
            backgroundColor: '#e2e2e2',

          },"& .MuiDataGrid-virtualScrollerRenderZone": {
            "& .MuiDataGrid-row": {
              backgroundColor: "rgba(255, 255, 255, .9)" 
            },},
            
        
          border: 2,
          fontSize: 15,
          borderColor: 'primary.light',
        }}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        experimentalFeatures={{ newEditingApi: true }}
        
      />
      
        <Modal
      onDismiss={() => setVisible(false)}
      visible={visible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={handleClose} variant="link">Cancel</Button>
            <Button onClick={handleSave} variant="primary">
               Confirm
               {spinner ?<LinearProgress /> : <></>}
               </Button>
          </SpaceBetween>
        </Box>
      }
      header="Review Changes"
    >
       <ExpandableSection 
        defaultExpanded
        header="Expand/Collapse">
      {Object.entries(changedData).map(([key, value], idx) =>
              <Container
              key={idx}
              header={
                  <Header variant="h3">
                  {value.app_name}
                  </Header>}>
                   {Object.entries(value).map(([tableKey, tableValue], idx_2) => 
                   tableKey != 'app_name' && tableKey != 'app_id' ?
                   <ColumnLayout key={idx_2} columns={2}>
                    {cleanText(tableKey) + ': '}
                    <Badge>{tableValue}</Badge>
                  </ColumnLayout> :
                  <br></br>
                  )}
              </Container>
            )}
      </ExpandableSection>
    </Modal>


    <Modal
      onDismiss={() => setClearVisible(false)}
      visible={clearVisible}
      closeAriaLabel="Close modal"
      footer={
        <Box float="right">
          
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={handleClearClose} variant="link">Cancel</Button>
            <Button onClick={clearChanges} variant="primary">
               Clear Changes
               {spinner ?<LinearProgress /> : <></>}
               </Button>
          </SpaceBetween>
        </Box>
      }
      header="Confirm Clear Changes"
    >
    Are you sure you wish to clear all your changes?
    </Modal>
    </div>
}
    </MuiThemeProvider>

  );
  
};

// Component TableView is a skeleton of a Table using AWS-UI React components.
export default UserTableRAGStatus;
