import React, { useState, useReducer, useMemo } from 'react';

import {
  Box,
  Button,
  CollectionPreferences,
  Pagination,
  Table,
  Link,
  PropertyFilter
} from '@awsui/components-react';
 import {
   getColumnDefinitions,
   getContentSelectorOptions,
   getPageSelectorOptions,
   getDefaultPreferences
} from '../resources/ItemTableConfig.jsx';
import { useCollection } from '@awsui/collection-hooks';
import { PROPERTY_FILTERING_I18N_CONSTANTS, GetFilteringDropdownOptions, GetFilteringProperties } from './ItemTableConfig';

import {
  resolveRelationshipValues,
  capitalize
} from '../resources/main.js'


import TableHeader from './TableHeader.jsx';

const ItemTable = (props) => {

  const locaStorageKeys = {
    tablePrefs: props.schemaName + "_table_prefs",
    tableAttributes: props.schemaName + "_table_attributes",
    tableQuery: props.schemaName + "_query",
    favQueries: props.schemaName + "_favourite_queries"
  }
  const [preferences, setPreferences] = useState(localStorage[locaStorageKeys.tablePrefs] ? JSON.parse(localStorage.getItem(locaStorageKeys.tablePrefs)) : getDefaultPreferences(props.schema, props.schemaKeyAttribute));
  const [contentAttributes, setContentAttributes] = useState(getContentSelectorOptions(props.schema));
  const [savedQuery, setSavedQuery] = useState(localStorage[locaStorageKeys.tableQuery] ? JSON.parse(localStorage.getItem(locaStorageKeys.tableQuery)) : {operation: "and", tokens: []})
  const [disableQuery, setDisableQuery] = useState(true)

  React.useEffect(() => {
    localStorage.setItem(locaStorageKeys.tablePrefs, JSON.stringify(preferences));
  }, [preferences]);
  // console.log(contentAttributes)
  // console.log(props.schema)
  const transformedItems = useMemo(() => resolveRelationshipValues(props.dataAll, props.items, props.schema), [props.dataAll, props.items, props.schema])
  const collectionResult = useCollection(
    transformedItems,
    {
      sorting: {},
      propertyFiltering: {
        defaultQuery: savedQuery,
        filteringProperties: GetFilteringProperties(getColumnDefinitions(props.schemaName, props.schema, props.provideLink ? props.provideLink : false), props.schemaName)      },
      pagination: { pageSize: preferences.pageSize },
    }
  );

 const {items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps} = collectionResult;
  React.useEffect(() => {
    localStorage.setItem(locaStorageKeys.tableQuery, JSON.stringify(propertyFilterProps.query))

    // Check if filter is empty
    setDisableQuery(propertyFilterProps.query.tokens.length === 0 ? true : false)
    // Check if filter exists in saved
    
  },[propertyFilterProps])

  function setFavourite(index) {
    delete index['id']
    delete index['name']
    localStorage.setItem(locaStorageKeys.tableQuery, JSON.stringify(index))
    window.location.reload()
  }


  // Keeps track of how many items are selected
  function headerCounter(selectedItems, items) {
    if(selectedItems !== undefined){
      return selectedItems.length
        ? `(${selectedItems.length} of ${items.length})`
        : `(${items.length})`;
    } else {
      return `(${items.length})`;
    }
  }

  function filterCounter(count) {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
  }
  async function handleSelectionChange(detail) {
    props.setSelectedItems(detail);
  }

  async function handleRefresh(e) {
    e.preventDefault();
    await props.handleRefreshClick(e);
    // Force update of current item to ensure latest data is available on viewer.

    // Search for previously selected items, and update based on refreshed data.
    let updatedItems = []
    if (props.selectedItems.length > 0) {
      for (const selectedItem of props.selectedItems) {
        const findResult = items.find(item => item[props.schemaKeyAttribute] === selectedItem[props.schemaKeyAttribute])

        if (findResult) {
          updatedItems.push(findResult);
        }
      }
      await props.handleSelectionChange(updatedItems);
    }
  }

  async function handleOnRowClick(detail) {
    if (props.handleSelectionChange){
      let selectedItem = []
      selectedItem.push(detail.item);

      await props.handleSelectionChange(selectedItem);
    }
  }

  function handleConfirmPreferences(detail) {
    let lPreferences = detail;
    let defaults = getDefaultPreferences(props.schema, props.schemaKeyAttribute);

    lPreferences.trackBy = defaults.trackBy;

    setPreferences(lPreferences);
  }
  function getEntityAccess() {
    let disabledButtons = {}
    if (props.userAccess) {
      //access permissions provided.
      if (props.userAccess[props.schemaName]) {

        if (props.userAccess[props.schemaName].create) {
          if (props.userAccess[props.schemaName].create == false){
            disabledButtons.add = true;
          }
        } else{
          //user does not have this right defined, disable button.
          disabledButtons.add = true;
        }
        if (props.userAccess[props.schemaName].update) {
          if (props.userAccess[props.schemaName].update == false) {
            disabledButtons.edit = true;
          }
        } else {
          //user does not have this right defined, disable button.
          disabledButtons.edit = true;
        }
        if (props.userAccess[props.schemaName].delete) {
          if (props.userAccess[props.schemaName].delete == false) {
            disabledButtons.delete = true;
          }
        } else{
          //user does not have this right defined, disable button.
          disabledButtons.delete = true;
        }
      } else
      {
        //access permissions provided but schema not present so default to no buttons enabled.
        disabledButtons.add = true;
        disabledButtons.edit = true;
        disabledButtons.delete = true;
      }
    }
    return disabledButtons;
  }

  //If attribute passed has a help_content key then the info link will be displayed.
  function displayHelpInfoLink(){

    if (!props.setHelpPanelContent || !props.schema.help_content){
      //SetHelp not provided so do not display.
      return undefined;
    }

    return <Link variant="info" onFollow={() => props.setHelpPanelContent(props.schema.help_content, false)}>Info</Link>
  }
  return (
      <Table
        {...collectionProps}
        trackBy={preferences.trackBy}
        columnDefinitions={getColumnDefinitions(props.schemaName, props.schema, props.provideLink ? props.provideLink : false)}
        visibleColumns={preferences.visibleContent}
        items={collectionResult.items}
        loading={!props.errorLoading ? props.isLoading : true}
        loadingText={!props.errorLoading ? "Loading " + props.schemaName + "s" : "Error getting data from API : " + props.errorLoading}
        resizableColumns={true}
        stickyHeader={true}
        empty={
          <Box textAlign="center" color="inherit">
            <b>No {props.schemaName + "s"}</b>
            <Box
              padding={{ bottom: "s" }}
              variant="p"
              color="inherit"
            >
              No {props.schemaName + "s"} to display.
            </Box>
            {props.handleAddItem ? <Button onClick={props.handleAddItem}>Add {props.schemaName}</Button> : undefined}
          </Box>
        }
        header={
          <TableHeader
            title={props.schema.friendly_name ? props.schema.friendly_name + "s" : capitalize(props.schemaName + "s")}
            description={props.description ? props.description : undefined}
            selectedItems={props.selectedItems ? props.selectedItems : undefined}
            counter={headerCounter(props.selectedItems, props.items)}
            handleSelectionClear={props.handleSelectionChange ? props.handleSelectionChange : undefined}
            info={displayHelpInfoLink()}
            handleActionSelection={props.handleAction ? props.handleAction : undefined}
            actionsButtonDisabled={props.actionsButtonDisabled}
            actionItems={props.actionItems ? props.actionItems : []}
            handleRefreshClick={props.handleRefreshClick ? handleRefresh : undefined}
            handleDeleteClick={props.handleDeleteItem ? props.handleDeleteItem : undefined}
            handleViewClick={props.handleViewItem ? props.handleViewItem : undefined}
            handleEditClick={props.handleEditItem ? props.handleEditItem : undefined}
            handleAddClick={props.handleAddItem ? props.handleAddItem : undefined}
            handleDownload={props.handleDownloadItems ? props.handleDownloadItems : undefined}
            disabledButtons={getEntityAccess()}
            setFavourite={setFavourite}
            schemaName={props.schemaName}
            queryDisabled={disableQuery}
            filteringProperties={propertyFilterProps.filteringProperties}
            handleUploadListClick = {props.handleListUpload ? props.handleListUpload : undefined}
            />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => handleConfirmPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: getPageSelectorOptions(props.schemaName)
            }}
            visibleContentPreference={{
              title: 'Select visible columns',
              options: contentAttributes
            }}
            wrapLinesPreference={{
              lsabel: 'Wrap lines',
              description: 'Check to see all the text and wrap the lines'
            }}
          />
        }
        wrapLines={preferences.wrapLines}
        selectedItems={props.selectedItems ? props.selectedItems : []}
        onSelectionChange={props.handleSelectionChange ? ({ detail }) => props.handleSelectionChange(detail.selectedItems) : null}
        onRowClick={({ detail }) => handleOnRowClick(detail)}
        selectionType={props.selectedItems ? props.selectionType ? props.selectionType : 'multi' : undefined} //If selectionItems not provided then selection disabled for table. Default to multi select if selectionType not provided.
        pagination={<Pagination {...paginationProps} />}
        filter={
        <>
        {/* Property Filtering in favor of Text Filter for advanced filtering */}
          <PropertyFilter
            {...propertyFilterProps}
            tokenLimit={3}
            i18nStrings={PROPERTY_FILTERING_I18N_CONSTANTS(props.schemaName.replaceAll('_',' '))}
            countText={filterCounter(filteredItemsCount)}
            expandToViewport
            filteringOptions={GetFilteringDropdownOptions(props.schemaName)}
            filteringProperties={GetFilteringProperties(getColumnDefinitions(props.schemaName, props.schema, props.provideLink ? props.provideLink : false), props.schemaName)}
          />
        </>
        }
      />
  );
};

export default ItemTable;
