import React from 'react';
import {useSchema} from '../actions/SchemaHook';

// Filtering options is needed for user experience - allows users to get dropdown options when filtering
export function GetFilteringDropdownOptions(pageName){
  // Taking the schema for attributes
  const [{schema: schemaLocal}] = useSchema();
  var filteringOptionsArray = []
  if (schemaLocal) {
    // Finding every attribute in pageName (i.e. application, servers, wave)
    for (let attributeIndex in schemaLocal[pageName]['attributes']) {
      var attribute = schemaLocal[pageName]['attributes'][attributeIndex]
      if (attribute['type'] === 'list') {
        var listOptions = attribute['listvalue'].split(",")
        // Breaking down the dropdown options and making them a filtering option
        for (let listItemIndex in listOptions) {
          var newEntry = {}
          newEntry['propertyKey'] = attribute['name']
          newEntry['value'] = listOptions[listItemIndex]
          filteringOptionsArray.push(newEntry)
        }
      }
    }
  }
  return filteringOptionsArray
}

// Gets all the possible column names to filter from 
export function GetFilteringProperties(columns, pageName) {
  // Removing the ability to filter over hidden attributes
  const [{schema: schemaLocal}] = useSchema();
  var hiddenAttributes = []
  if (schemaLocal) {
    for (let attributeIndex in schemaLocal[pageName]['attributes']) {
      var attribute = schemaLocal[pageName]['attributes'][attributeIndex]
      if (attribute['hidden']) {
        hiddenAttributes.push(attribute['name'])
      }
    }
  }

  // Getting all possible column names to filter over
  var filteringArray = []
  for (let column of columns) {
      if (hiddenAttributes.includes(column['id'])) {
        continue;
      }
      var newFilter = {}
      newFilter['propertyLabel'] = column['header']
      newFilter['key'] = column['id']
      newFilter['groupValuesLabel'] = column['header'] + ' values'
      newFilter['operators'] = [':', '!:', '=', '!=']
      filteringArray.push(newFilter)
  };
  return filteringArray
}

export function PROPERTY_FILTERING_I18N_CONSTANTS(pageName) {
  return {
    filteringAriaLabel: 'your choice',
    dismissAriaLabel: 'Dismiss',

    filteringPlaceholder: 'Search ' + pageName + 's',
    groupValuesText: 'Values',
    groupPropertiesText: 'Properties',
    operatorsText: 'Operators',

    operationAndText: 'and',
    operationOrText: 'or',

    operatorLessText: 'Less than',
    operatorLessOrEqualText: 'Less than or equal',
    operatorGreaterText: 'Greater than',
    operatorGreaterOrEqualText: 'Greater than or equal',
    operatorContainsText: 'Contains',
    operatorDoesNotContainText: 'Does not contain',
    operatorEqualsText: 'Equals',
    operatorDoesNotEqualText: 'Does not equal',

    editTokenHeader: 'Edit filter',
    propertyText: 'Property',
    operatorText: 'Operator',
    valueText: 'Value',
    cancelActionText: 'Cancel',
    applyActionText: 'Apply',
    allPropertiesLabel: 'All properties',

    tokenLimitShowMore: 'Show more',
    tokenLimitShowFewer: 'Show fewer',
    clearFiltersText: 'Clear filters',
    removeTokenButtonAriaLabel: token => `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
    enteredTextLabel: text => `Use: "${text}"`,
  }
};