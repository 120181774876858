//Component used for providing generic edit record/item screen.
// All schema entities use this component.

import React, {useEffect} from 'react';
import {
  SpaceBetween,
  Form,
  Header,
  Button,
  Multiselect,
  Container,
  Badge,
  ColumnLayout,
  Pagination,
  ExpandableSection,
  ProgressBar,
  Flashbar,
  Toggle,
  Grid
 } from '@awsui/components-react';

import { useModal } from '../actions/Modal.js';
import AllAttributes from './ui_attributes/AllAttributes.jsx'

import { useState } from 'react';
import {capitalize, setNestedValuePath} from "../resources/main";
const MultipleItemAmend = (props) => {
    const [
        currentPageIndex,
        setCurrentPageIndex
      ] = React.useState(1);
  const [progress, setProgress] = useState(0)
  const [localItem, setLocalItem] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [validForm, setFormValidation] = useState(false);
  const [formErrors, setFormErrors] = useState([]); //List of error messages on this form, these are displayed in the bottom of the form.
  const [isSaving, setIsSaving] = useState(false);
  const [options, setOptions] = useState([]);

  // COMMENTS
  const [commentsField, setCommentsField] = useState(false)
  const [checked, setChecked] = useState(true);

  const [localSchema, setLocalSchema] = useState({
    attributes : [],
    lastModifiedTimestamp : props.schemas[props.schemaName]['lastModifiedTimestamp'],
    schema_name :  props.schemas[props.schemaName]['schema_name'],
    schema_type : props.schemas[props.schemaName]['schema_type']
  })
  const [id, setID] = useState()
  const [name, setName] = useState()
  const [
    selectedOptions,
    setSelectedOptions
  ] = React.useState([]);

  // Whenever user selects an option, allow user to see attribute to edit
  useEffect(() => {
    setCommentsField(selectedOptions.some(attr => attr.description === 'comments'))
    var attributes = []
    for (var option in selectedOptions) {
        for (var attr in props.schemas[props.schemaName]['attributes']) {
            if (props.schemas[props.schemaName]['attributes'][attr]['name'] === selectedOptions[option]['description']) {
                attributes.push(props.schemas[props.schemaName]['attributes'][attr])
                break;
            }
        }
    }
    
    var tempSchema = localSchema
    tempSchema.attributes = attributes
    setLocalSchema(local => ({...local, "attributes": attributes}))

    // Implement when users remove elements, remove its record
    var updatedRecord = {}
    for (var entryIndex in selectedOptions) {
        var entry = selectedOptions[entryIndex]['description']
        if (localItem[entry] !== '' & localItem[entry] !== undefined ) {
            updatedRecord[entry] = localItem[entry]
        }
    }
    setLocalItem(updatedRecord);
    
  }, [selectedOptions]);


  // Finding existing values for enhanced user experience
  function existingValues() {

    return (
        <ExpandableSection 
        defaultExpanded
        header="Current Values">
      
        {props.selection.map( function(object,i) {
            return (
            <>
            <Container
            header={
                <Header variant="h3">
                {props.schemaName.charAt(0).toUpperCase() + props.schemaName.slice(1) +  " Name: " + object[name]}
                </Header>}>
                 <ColumnLayout columns={2}>
                {selectedOptions.map(function(o, i) {
                    var value;
                    if (object[o['description']] === undefined) {
                        value = <Badge color="red">Empty</Badge> 
                    } else {
                        value = <Badge >{object[o['description']]}</Badge> 
                    }
                    return(
                        <>
                        { o['label'] + ' : '}
                        {value}
                        </>
                    )
                })}
                </ColumnLayout>
            </Container>
            </>
        )
        })}
        </ExpandableSection>

        )
    

  }

  //Modals
  const { show: showUnsavedConfirmaton, hide: hideUnsavedConfirmaton, RenderModal: UnSavedModal } = useModal()

  async function handleUserInput (value){
 
    let valueArray = [];
    let localFormValidation = true;
    let newRecord = Object.assign({}, localItem);

    //Convert non-Array values to array in order to keep procedure simple.
    if(Array.isArray(value)){
      valueArray = value;
    } else {
      valueArray.push(value);
    }

    for (const valueItem of valueArray){
      if (Array.isArray(valueItem.value)){
        if(valueItem.value.length > 0){
          //Check first item to see if tag structure.
          if((valueItem.value[0].existing === true || valueItem.value[0].existing === false) && 'key' in valueItem.value[0] && 'value' in valueItem.value[0]){
            //It's a tag field!!
            let updatedTags = valueItem.value.map((item) => {
              if (item.existing === false)
              {
                return {key: item.key, value: item.value};
              }

              if (item.existing && !item.markedForRemoval) {
                return {key: item.key, value: item.value};
              }

              return null;

            });

            //newRecord[value.field] = updatedTags;
            setNestedValuePath(newRecord, valueItem.field, updatedTags);

          } else {
            //Not a tag field just an array.
            //newRecord[value.field] = value.value;
            setNestedValuePath(newRecord, valueItem.field, valueItem.value);
          }
        } else {
          // Array to be emptied. Set as empty.
          setNestedValuePath(newRecord, valueItem.field, []);
        }
      }
      else {
        //newRecord[value.field] = value.value;
        setNestedValuePath(newRecord, valueItem.field, valueItem.value);
      }
    }
    // Implement when users dont fill out an elements, remove its record]
    var updatedRecord = {}
    for (var entryIndex in selectedOptions) {
        var entry = selectedOptions[entryIndex]['description']
        if (newRecord[entry] !== '' & newRecord[entry] !== undefined) {
            updatedRecord[entry] = newRecord[entry]
        }
    }
    setLocalItem(updatedRecord);
    setDataChanged(true);
  }

  async function handleSave (e){
    e.preventDefault();

    setIsSaving(true);
    
    // Multi Save functionality - hitting API
    for (var item in props.selection) {

        var updatedItem = localItem
        updatedItem[id] = props.selection[item][id]
        updatedItem[name] = props.selection[item][name]
        if (item == props.selection.length - 1) {
            await props.handleSave(updatedItem, props.action, 'multi-edit-finished', checked)
        } else {
            await props.handleSave(updatedItem, props.action, 'multi-edit-ongoing', checked)
        }
        setProgress(Math.ceil((item/props.selection.length)*100))
    }

    setIsSaving(false);
  }

  function handleCancel (e){
    e.preventDefault();

    if (dataChanged){
      showUnsavedConfirmaton();
    } else {
      props.handleCancel(e);
    }
  }

  function handleUpdateFormErrors (newErrors){
    setFormErrors(newErrors);
  }

  useEffect(() => {
    if (props.schemaName === 'application') {
        setID('app_id')
        setName('app_name')
    } else if (props.schemaName === 'wave') {
        setID('wave_id')
        setName('wave_name')
    } else if (props.schemaName === 'server') {
        setID('server_id')
        setName('server_name')
    }
    if (formErrors.length === 0){
      setFormValidation(true);
    } else {
      setFormValidation(false);
    }

  }, [formErrors]);

  useEffect(() => {
    var options = []

    //  Creating the multi select option dropdown list
    for (let attr in props.schemas[props.schemaName]['attributes']) {
        if (props.schemas[props.schemaName]['attributes'][attr]['name'] === id || props.schemas[props.schemaName]['attributes'][attr]['name'] === name) {
            continue
        } else {
        var multiSelectOption = {}
        multiSelectOption['label'] = props.schemas[props.schemaName]['attributes'][attr]['description']
        multiSelectOption['value'] = props.schemas[props.schemaName]['attributes'][attr]['description']
        multiSelectOption['description'] = props.schemas[props.schemaName]['attributes'][attr]['name']
        options.push(multiSelectOption) 
        }
    }
    setOptions(options)

  }, [id, name]);


  function headerText() {
    let text = props.action ? capitalize(props.action + ' multiple ' + props.schemaName + 's') : capitalize(props.schemaName);

    if (props.schemas[props.schemaName].friendly_name) {
      text = props.action ? capitalize(props.action + ' multiple ' + props.schemas[props.schemaName].friendly_name + 's') : props.schemas[props.schemaName].friendly_name;
    }

    return text;
  }

  return (
    <div>
         <SpaceBetween size="l">
        {isSaving ? 
         <Flashbar items={[{
             content: (
        <ProgressBar
        variant="flash"
        value={progress}
        description="Multi Edit currently executing, please DO NOT leave this page..."
        label="Multi Edit in Progress"
        /> ),
        id: "multi-edit-bar"}]}
        />
        : <></>}
      <Form
        header={ <>
            <Grid
            gridDefinition={[
              { colspan: { default: 12, xxs: 9 } },
              { colspan: { default: 12, xxs: 1.5 } },
              { colspan: { default: 12, xxs: 1.5 } }
            ]}
          >
            <Header variant="h1">{headerText()}</Header>
            <Button onClick={handleCancel} variant="link">Cancel</Button>
              <Button onClick={handleSave} disabled={!validForm} variant="primary" loading={isSaving}>
                Save
              </Button>
              </Grid>
            </>}
        actions={
          // located at the bottom of the form
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={handleCancel} variant="link">Cancel</Button>
            <Button onClick={handleSave} disabled={!validForm} variant="primary" loading={isSaving}>
              Save
            </Button>
          </SpaceBetween>
        }
        errorText={formErrors.length > 0 ? formErrors.map(error => {
            let errorReason = error.validation_regex_msg ? error.validation_regex_msg : 'You must specify a value.';
            return <p>{error.description + ' - ' + errorReason}</p>}): undefined
        }
      >
        <SpaceBetween size="l">

        <Header variant="h1">Choose Attributes you want to update</Header>
        <Header variant="h3">Note: Attributes that are chosen and left empty will not be updated.
        If {props.schemaName} is unchanged, then Save will skip/error on that {props.schemaName}</Header>
        <Multiselect
        selectedOptions={selectedOptions}
        onChange={({ detail }) =>
        setSelectedOptions(detail.selectedOptions)
        }
        deselectAriaLabel={e => `Remove ${e.label}`}
        options={options}
        placeholder="Choose attributes"
        selectedAriaLabel="Selected"
        />

        <Container 
        header={<Header 
        description={"This denotes the current values of your selection. If badge is Red then the field is empty"}
        variant="h3">Current Values</Header>}>
            <SpaceBetween size="xs">
            {existingValues()}
            </SpaceBetween>
        </Container>

        {!commentsField ? <></>:
        <Container>
                 <ColumnLayout columns={2}>
                  Enable Comment Append?
                  <Toggle
                  onChange={({ detail }) =>
                    setChecked(detail.checked)
                  }
                  checked={checked}
                >
                {checked ? 'Enabled' : 'Disabled'}
                </Toggle>
                </ColumnLayout>
            </Container>
            }
          <SpaceBetween size="l">
            <AllAttributes
                schema={localSchema}
                schemaName={props.schemaName}
                schemas={props.schemas}
                userAccess={props.userAccess}
                item={localItem}
                handleUserInput={handleUserInput}
                handleUpdateValidationErrors={handleUpdateFormErrors}
                setHelpPanelContent={props.setHelpPanelContent}
                />

          </SpaceBetween>
    </SpaceBetween>
      </Form>
      </SpaceBetween>
      <UnSavedModal title={'Unsaved changes'} onConfirmation={props.handleCancel}>Changes made will be lost if you continue, are you sure?</UnSavedModal>
    </div>
  );
};

export default MultipleItemAmend;
