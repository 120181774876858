import React, {useEffect, useState} from "react";
import SideNavigation from "@awsui/components-react/side-navigation";
import {capitalize} from "../resources/main";
import {useLocation, useNavigate} from "react-router-dom";


function ServiceNavigation(props) {
  let navigate = useNavigate();
  const [items, setItems] = useState([]);
  let location = useLocation()

  // If the provided link is empty, do not redirect pages
  function onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      navigate(ev.detail.href);
    }
  }

  useEffect( () => {

    let navItems = [];

    if (props.userGroups){
      if (props.userGroups.includes("admin")){
        navItems = itemsAdmin;
      } else
      {
        navItems = itemsUser;
      }
    } else {
      //Default to user items only.
      navItems = itemsUser;
    }

    let navSchemaItems = []
    let order = ['wave','application','server', 'stakeholdermanagement', 'stakeholder']
    navSchemaItems.push({ type: "link", text: 'RAG Status', href: '/rag-status' })

    for (const schema in order) {
      for (const item in props.schemaMetadata) {
        if (props.schemaMetadata[item]['schema_name'] === order[schema]) {
          if (order[schema] == 'stakeholdermanagement') {
            navSchemaItems.push({ type: "link", text: props.schemaMetadata[item]['friendly_name'] ? props.schemaMetadata[item]['friendly_name'] : capitalize('stakeholder Management').replaceAll('_',' '), href: '/' + props.schemaMetadata[item]['schema_name'] + 's' })
          } else {
           navSchemaItems.push({ type: "link", text: props.schemaMetadata[item]['friendly_name'] ? props.schemaMetadata[item]['friendly_name'] : capitalize(props.schemaMetadata[item]['schema_name']).replaceAll('_',' '), href: '/' + props.schemaMetadata[item]['schema_name'] + 's' })
          }
        }
      }

    }
    navSchemaItems.push({ type: "link", text: "Import", href: "/import" });
    navSchemaItems.push({ type: "link", text: "Export", href: "/export" });
    navItems[1].items = navSchemaItems;

    setItems(navItems);

  }, [props.userGroups, props.schemaMetadata])

  return (
    <SideNavigation
      header={{ text: "Cloud Migration Tracker", href: "/" }}
      items={items}
      activeHref={`${location.pathname}`}
      onFollow={onFollowHandler}
    />
  );
}

const itemsUser = [
  {
    type: "section",
    text: "Overview",
    items: [{ type: "link", text: "Dashboard", href: "/" }],
  },
  {
    type: "section",
    text: "Migration Management",
    items: [
    ],
  },
  { type: "divider" },
  {
    type: "link",
    text: "VERSION_UI" in window.env ? "Version: " + window.env.VERSION_UI : null,
  },
];

const itemsAdmin = [
  {
    type: "section",
    text: "Overview",
    items: [{ type: "link", text: "Dashboard", href: "/" }],
  },
  {
    type: "section",
    text: "Migration Management",
    items: [
    ],
  },
  {
    type: "section",
    text: "Administration",
    //defaultExpanded: false,
    items: [
      { type: "link", text: "Permissions", href: "/admin/policy" },
      { type: "link", text: "Attributes", href: "/admin/attribute" },
      {
        type: "link",
        text: "Credential Manager",
        href: "/admin/credential-manager",
      },
    ],
  },
  { type: "divider" },
  {
    type: "link",
    text: "VERSION_UI" in window.env ? "Version: " + window.env.VERSION_UI : null,
  },
];

export default ServiceNavigation;
