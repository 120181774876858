import React from "react";
import { Route, Routes } from "react-router-dom";
import UserTableApps from "./containers/UserTableApps";
import UserServerTable from "./containers/UserTableServers";
import UserDatabaseTable from "./containers/UserTableDatabases";
import UserWaveTable from "./containers/UserTableWaves";
import UserAutomationJobs from "./containers/UserAutomationJobs";
import UserAutomationScripts from "./containers/UserAutomationScripts";
import UserDashboard from "./containers/UserDashboard";
import UserImport from "./containers/UserImport";
import UserExport from "./containers/UserExport";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import AdminPolicy from "./containers/AdminPolicy";
import AdminSchemaMgmt from "./containers/AdminSchemaMgmt"
import ForgotPassword from "./containers/ForgotPassword";
import ChangePassword from "./containers/ChangePassword";
import CredentialManager from "./containers/CredentialManager";
import Secrets from "./containers/Secrets";
import UserStakeholderTable from "./containers/UserTableStakeholders";
import UserStakeholderManagementTable from "./containers/UserTableStakeholderManagements";
import UserTableRAGStatus from "./containers/UserTableRAGStatus";

export default ({ childProps }) => {
  return (

    <Routes>


<Route
       path="/stakeholdermanagements"
       element={
           <UserStakeholderManagementTable {...childProps}/>
     }>
        
      </Route>

      <Route
       path="/stakeholdermanagements/add"
       element={
        <UserStakeholderManagementTable {...childProps}/>
      }>
        
      </Route>

      <Route
       path="/stakeholdermanagements/:id"
       element={
        <UserStakeholderManagementTable {...childProps}/>
      }>
        
      </Route>

      <Route
       path="/stakeholdermanagements/view/:id"
       element={
        <UserStakeholderManagementTable {...childProps}/>
      }>
        
      </Route>

      <Route
       path="/stakeholdermanagements/edit/:id"
       element={
        <UserStakeholderManagementTable {...childProps}/>
      }>
        
      </Route>


      <Route
       path="/stakeholders"
       element={
           <UserStakeholderTable {...childProps}/>
     }>
        
      </Route>

      <Route
       path="/rag-status"
       element={
           <UserTableRAGStatus {...childProps}/>
     }>
        
      </Route>

      <Route
       path="/stakeholders/add"
       element={
           <UserStakeholderTable {...childProps}/>
     }>
        
      </Route>

      <Route
       path="/stakeholders/view/:id"
       element={
           <UserStakeholderTable {...childProps}/>
     }>
        
      </Route>
      <Route
       path="/stakeholders/edit/:id"
       element={
           <UserStakeholderTable {...childProps}/>
     }>
        
      </Route>


      <Route
       path="/stakeholders/:id"
       element={
           <UserStakeholderTable {...childProps}/>
     }>
        
      </Route>

      <Route
        path="/"
        element={
            <UserDashboard {...childProps}/>
      }
      />
      <Route
        path="/applications"
        element={
            <UserTableApps {...childProps}/>
        }
      />
      <Route
        path="/applications/:id"
        element={
          <UserTableApps {...childProps}/>
        }
      />
      <Route
        path="/applications/add"
        element={
          <UserTableApps {...childProps}/>
        }
      />
      <Route
        path="/applications/edit/:id"
        element={
          <UserTableApps {...childProps}/>
        }
      />
       <Route
        path="/applications/view/:id"
        element={
          <UserTableApps {...childProps}/>
        }
      />
      <Route
        path="/servers"
        element={
          <UserServerTable {...childProps}/>
        }
      />
      <Route
        path="/servers/:id"
        element={
          <UserServerTable {...childProps}/>
        }
      />
      <Route
        path="/servers/view/:id"
        element={
          <UserServerTable {...childProps}/>
        }
      />
      <Route
        path="/servers/add"
        element={
          <UserServerTable {...childProps}/>
        }
      />
      <Route
        path="/servers/edit/:id"
        element={
          <UserServerTable {...childProps}/>
        }
      />
      <Route
        path="/waves"
        element={
          <UserWaveTable {...childProps}/>
        }
      />
      <Route
        path="/waves/:id"
        element={
          <UserWaveTable {...childProps}/>
        }
      />
      <Route
        path="/waves/add"
        element={
          <UserWaveTable {...childProps}/>
        }
      />
      <Route
        path="/waves/view/:id"
        element={
          <UserWaveTable {...childProps}/>
        }
      />
      <Route
        path="/waves/edit/:id"
        element={
          <UserWaveTable {...childProps}/>
        }
      />
      <Route
        path="/databases"
        element={
          <UserDatabaseTable {...childProps}/>
        }
      />
      <Route
        path="/databases/:id"
        element={
          <UserDatabaseTable {...childProps}/>
        }
      />
      <Route
        path="/databases/add"
        element={
          <UserDatabaseTable {...childProps}/>
        }
      />
      <Route
        path="/databases/edit/:id"
        element={
          <UserDatabaseTable {...childProps}/>
        }
      />
      <Route
        path="/import"
        element={
          <UserImport {...childProps}/>
        }
      />
      <Route
        path="/export"
        element={
          <UserExport {...childProps}/>
        }
      />
      <Route
        path="/secrets"
        element={
          <Secrets {...childProps}/>
        }
      />
      <Route
        path="/automation/jobs"
        element={
          <UserAutomationJobs {...childProps}/>
        }
      />
      <Route
        path="/automation/jobs/:id"
        element={
          <UserAutomationJobs {...childProps}/>
        }
      />
      <Route
        path="/automation/scripts"
        element={
          <UserAutomationScripts {...childProps}/>
        }
      />
      <Route
        path="/automation/scripts/add"
        element={
          <UserAutomationScripts {...childProps}/>
        }
      />
      <Route path="/login" exact render={(props) => (
        <Login {...props} props={childProps} />
      )} />
      <Route
        path="/change/pwd"
        element={
          <ChangePassword {...childProps}/>
        }
      />
      childProps.userGroups ?
      childProps.userGroups.includes('admin')
      ?(
      <Route
        path="/admin/policy"
        element={
          <AdminPolicy {...childProps}/>
        }
      />
      <Route
        path="/admin/attribute"
        element={
          <AdminSchemaMgmt {...childProps}/>
        }
      />
      <Route
        path="/admin/credential-manager"
        element={
          <CredentialManager {...childProps}/>
        }
      />
      )
     :
      undefined
      :
      undefined
      }
      { /* Finally, catch all unmatched routes */}
      <Route component={NotFound} />
    </Routes>)
};
