import React from 'react';
import clsx from 'clsx';
import InputBase from '@mui/material/InputBase';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';

export function getColumns() {
    
  const columnMapping = [
    {field: 'app_id', headerName: 'Application ID', cellClassName: 'super-app disabled',
    type: 'string', width: 80, editable: false},
    {field: 'wave_id', headerName: 'Wave Number', cellClassName: 'super-app disabled',type: 'string', editable: false},
    // {field: 'wave_status', headerName: 'Wave Status', cellClassName: 'super-app disabled',type: 'string', editable: false},
    {field: 'app_name', headerName: 'Application', cellClassName: 'super-app disabled',editable: false, width: 150,
     type: 'string'},
    // {field: 'no_of_servers', headerName: 'Server Count', cellClassName: 'super-app disabled',type: 'integer', editable: false, width: 120},
    {field: 'rag_status', headerName: 'RAG Status*', type: 'singleSelect',
     valueOptions: ['Red', 'Amber', 'Green', 'Completed'],
     editable: true,
     width:  100,
     cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        return clsx('super-app', {
          red: params.value === 'Red',
          amber: params.value =='Amber',
          green: params.value == 'Green',
          completed: params.value == 'Completed'
        })}},
    {field: 'rag_comments', headerName: 'RAG Comments*', 
    width:  1000,
    editable: true,
    renderEditCell: renderEditTextarea
    },
]
  var columnDefinition = []
  for (var i in columnMapping) {
    var dict = columnMapping[i]
    columnDefinition.push(dict)
  }
  return columnDefinition
};

const renderEditTextarea = (params) => <EditTextarea {...params} />;

const EditTextarea = (props) => {
    const { id, field, value, colDef, api } = props;
    const [valueState, setValueState] = React.useState(value);
    const [anchorEl, setAnchorEl] = React.useState();
  
    const handleRef = (el) => {
      setAnchorEl(el);
    };
  
    const handleChange = React.useCallback(
      (event) => {
        const newValue = event.target.value;
        setValueState(newValue);
        api.setEditCellValue({ id, field, value: newValue }, event);
      },
      [api, field, id]
    );
  
    return (
      <div>
        <div
          ref={handleRef}
          style={{
            height: 1,
            width: colDef.computedWidth,
            display: "block",
        
          }}
        />
        {anchorEl && (
          <Popper open anchorEl={anchorEl} style={{ zIndex: 100 }}>
            <Paper elevation={1} sx={{ p: 1, minWidth: colDef.computedWidth }}>
              <InputBase
                multiline
                rows={16}
                value={valueState}
                sx={{ fontSize: 15, textarea: { resize: "both" }, width: "100%" }}
                onChange={handleChange}
              />
            </Paper>
          </Popper>
        )}
      </div>
    );
  };

export function getRows(allData) {
    var dataRAG = [];
    for (var index in allData['appData']) {
        var idx = allData['waveData'].findIndex(p => p.wave_id == allData['appData'][index]['wave_id']);
        if(('wave_id' in allData['appData'][index] && allData['waveData'][idx]?.wave_status != 'Completed')){
        var dataRAGIndividual = {}
        dataRAGIndividual['app_id'] = allData['appData'][index]['app_id']
        dataRAGIndividual['app_name'] = allData['appData'][index]['app_name']
        if('rag_comments' in allData['appData'][index]) dataRAGIndividual['rag_comments'] = allData['appData'][index]['rag_comments']
        if('rag_status' in allData['appData'][index]) dataRAGIndividual['rag_status'] = allData['appData'][index]['rag_status']
        dataRAGIndividual['id'] = allData['appData'][index]['app_id']
        if('wave_id' in allData['appData'][index]) {
            dataRAGIndividual['wave_id'] = allData['waveData'][idx]?.wave_name
        }
        dataRAG.push(dataRAGIndividual)
        }
    }
    return dataRAG.sort((left, right) => (left.wave_id > right.wave_id ? 1 : -1))
};

