//Component used for providing generic edit record/item screen.
// All schema entities use this component.

import React, {useEffect} from 'react';
import {
  SpaceBetween,
  Form,
  Header,
  Button,
  Grid
 } from '@awsui/components-react';

import { useModal } from '../actions/Modal.js';
import AllAttributes from './ui_attributes/AllAttributes.jsx'

import { useState } from 'react';
import {capitalize, setNestedValuePath} from "../resources/main";

const ItemView = (props) => {

  const [localItem, setLocalItem] = useState(props.item);
  const [dataChanged, setDataChanged] = useState(false);
  const [validForm, setFormValidation] = useState(false);
  const [formErrors, setFormErrors] = useState([]); //List of error messages on this form, these are displayed in the bottom of the form.
  const [isSaving, setIsSaving] = useState(false);
  const [disabledButton, setDisabledButton] = useState({
    add : false,
    edit : false,
    delete : false
  })
  //Modals
  const { show: showUnsavedConfirmaton, hide: hideUnsavedConfirmaton, RenderModal: UnSavedModal } = useModal()

  function handleCancel (e){
    e.preventDefault();

    if (dataChanged){
      showUnsavedConfirmaton();
    } else {
      props.handleCancel(e);
    }
  }

  useEffect(() => {
    let disabledButtons = {}
    if (props.userAccess) {
      //access permissions provided.
      if (props.userAccess[props.schemaName]) {

        if (props.userAccess[props.schemaName].create) {
          if (props.userAccess[props.schemaName].create == false){
            disabledButtons.add = true;
          }
        } else{
          //user does not have this right defined, disable button.
          disabledButtons.add = true;
        }
        if (props.userAccess[props.schemaName].update) {
          if (props.userAccess[props.schemaName].update == false) {
            disabledButtons.edit = true;
          }
        } else {
          //user does not have this right defined, disable button.
          disabledButtons.edit = true;
        }
        if (props.userAccess[props.schemaName].delete) {
          if (props.userAccess[props.schemaName].delete == false) {
            disabledButtons.delete = true;
          }
        } else{
          //user does not have this right defined, disable button.
          disabledButtons.delete = true;
        }
      } else
      {
        //access permissions provided but schema not present so default to no buttons enabled.
        disabledButtons.add = true;
        disabledButtons.edit = true;
        disabledButtons.delete = true;
      }
    }
    setDisabledButton(disabledButtons);
  }, [props.userAccess])

  function handleUpdateFormErrors (newErrors){
    setFormErrors(newErrors);
  }

  useEffect(() => {
    if (formErrors.length === 0){
      setFormValidation(true);
    } else {
      setFormValidation(false);
    }
  }, [formErrors]);


  function headerText() {
    let text = props.action ? capitalize(props.action + ' ' + props.schemaName) : capitalize(props.schemaName);

    if (props.schemas[props.schemaName].friendly_name) {
      text = props.action ? capitalize(props.action + ' ' + props.schemas[props.schemaName].friendly_name) : props.schemas[props.schemaName].friendly_name;
    }

    return text;
  }
  return (
    <div>
      <Form
        header={ <>
          <Grid
            gridDefinition={[
              { colspan: { default: 12, xxs: 9 } },
              { colspan: { default: 12, xxs: 1.5 } },
              { colspan: { default: 12, xxs: 1.5 } }
            ]}
        >
          <Header variant="h1">{headerText()}</Header>
          <Button onClick={props.handleEditItem} disabled={disabledButton.edit} variant='primary'>Edit</Button>
            <Button onClick={handleCancel} variant="primary">Done</Button>
            </Grid>
          </>}
        actions={
          // located at the bottom of the form
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={props.handleEditItem} disabled={disabledButton.edit} variant='primary'>Edit</Button>
            <Button onClick={handleCancel} variant="primary">Done</Button>
          </SpaceBetween>
        }
      >
          <SpaceBetween size="l">
            <AllAttributes
                view={true}
                schema={props.schemas[props.schemaName]}
                schemaName={props.schemaName}
                schemas={props.schemas}
                userAccess={props.userAccess}
                item={localItem}
                handleUpdateValidationErrors={handleUpdateFormErrors}
                setHelpPanelContent={props.setHelpPanelContent}
            />

          </SpaceBetween>
      </Form>
    </div>
  );
};

export default ItemView;
