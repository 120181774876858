import React, {useEffect, useState} from 'react';
import User from "../actions/user";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import { Auth } from "aws-amplify";
import ItemAmend from "../components/ItemAmend";
import {
  capitalize,
  exportTable,
  getChanges
} from '../resources/main.js'

import {
  SpaceBetween,
  StatusIndicator
} from '@awsui/components-react';

import StakeholderView from '../components/StakeholderView.jsx';
import { useMFApps } from "../actions/ApplicationsHook";
import { useGetServers } from "../actions/ServersHook.js";
import { useMFWaves } from "../actions/WavesHook.js";
import ItemTable from '../components/ItemTable.jsx';
import { useModal } from '../actions/Modal.js';
import {parsePUTResponseErrors} from "../resources/recordFunctions";
import MultipleItemAmend from "../components/MultipleItemAmend";
import {useGetStakeholders} from "../actions/StakeholdersHook.js";
import ItemView from "../components/ItemView";


const UserStakeholderTable = (props) => {
  let location = useLocation()
  let navigate = useNavigate();
  let params = useParams();
  const [editingMultipleItems, setEditingMultipleItems] = useState(false);

  //Data items for viewer and table.
  const [{ isLoading: isLoadingApps, data: dataApps, error: errorApps }, { update: updateApps }] = useMFApps();
  const [{ isLoading: isLoadingServers, data: dataServers, error: errorServers }, { update: updateServers }] = useGetServers();
  const [{ isLoading: isLoadingWaves, data: dataWaves, error: errorWaves }, { update: updateWaves }] = useMFWaves();
  const [{ isLoading: isLoadingStakeholders, data: dataStakeholders, error: errorStakeholders}, {update: updateStakeholders}] = useGetStakeholders();
  
  const dataAll = {application: {data: dataApps, isLoading: isLoadingApps, error: errorApps}, server: {data: dataServers, isLoading: isLoadingServers, error: errorServers}, wave: {data: dataWaves, isLoading: isLoadingWaves, error: errorWaves}, stakeholder: {data: dataStakeholders, isLoading: isLoadingStakeholders, error: errorStakeholders}};
  
  
  //Layout state management.
  const [editingItem, setEditingItem] = useState(false);

  //Main table state management.
  const [selectedItems, setSelectedItems] = useState([]);
  const [focusItem, setFocusItem] = useState([]);
  const [viewItem, setViewItem] = useState(false);


  //Viewer pane state management.
  const [viewerCurrentTab, setViewerCurrentTab] = useState('details');
  const [action, setAction] = useState(['Add']);

  //Get base path from the URL, all actions will use this base path.
  const basePath = location.pathname.split('/').length >= 2 ? '/' + location.pathname.split('/')[1] : '/';

  //Key for main item displayed in table.
  const itemIDKey = 'stakeholder_id';
  const schemaName = 'stakeholder';

  const { show: showDeleteConfirmaton, hide: hideDeleteConfirmaton, RenderModal: DeleteModal } = useModal()

  function handleNotification(notification)
  {
    return props.updateNotification('add', notification)
  }

  function handleAddItem()
  {
    navigate({
      pathname: basePath + '/add'
    })
    setAction('Add')
    setFocusItem({});
    setEditingItem(true);
    setViewItem(false);

  }

  function handleDownloadItems()
  {
    if (selectedItems.length > 0 ) {
      // Download selected only.
      exportTable(selectedItems, "Stakeholder", "stakeholder")
    } else {
      //Download all.
      exportTable(dataStakeholders, "Stakeholder", "stakeholder")
    }
  }

  function handleEditItem(selection = null)
  {
    if ( selectedItems.length === 1) {
      navigate({
        pathname: basePath + '/edit/' + selectedItems[0][itemIDKey]
      })
      setAction('Edit')
      setFocusItem(selectedItems[0]);
      setEditingItem(true);
      setViewItem(false);

    } else if ( selection ) {
      navigate({
        pathname: basePath + '/edit/' + selection[itemIDKey]
      })
      setAction('Edit');
      setFocusItem(selection);
      setEditingItem(true);
      setViewItem(false);

    }

  }

  function handleViewItem(selection = null)
  {
    if ( selectedItems.length === 1) {
      navigate({
        pathname: basePath + '/view/' + selectedItems[0][itemIDKey]
      })
      setAction('View')
      setFocusItem(selectedItems[0]);
      setEditingItem(true);
      setViewItem(true)
    } else if ( selection ) {
      navigate({
        pathname: basePath + '/view/' + selection[itemIDKey]
      })
      setAction('View');
      setFocusItem(selection);
      setEditingItem(true);
      setViewItem(true);
    }

  }

  async function handleViewerTabChange(tabselected)
  {
      setViewerCurrentTab(tabselected);
  }

  function handleResetScreen()
  {
    navigate({
      pathname: basePath
    })
    setEditingItem(false);
    setViewItem(false);

  }

  function handleItemSelectionChange(selection) {

    setSelectedItems(selection);
    if (selection.length === 1) {

      //TO-DO Need to pull in Waves or other data here.
      //updateApps(selection[0].app_id);

      setEditingMultipleItems(false)

    } else if (selection.length > 1 ) {
      // setEditingMultipleItems(true)
    }
    //Reset URL to base table path.
    navigate({
      pathname: basePath
    })

  }

  function getCurrentStakeholderDetails() {

    if (selectedItems.length === 1) {
      let apps = dataApps.filter(function (entry) {
        return entry.app_id === selectedItems[0].app_id;
      });

      if ( apps.length > 0){
        return apps;
      } else {
        return [];
      }
    }
  }

  async function handleSave(editItem, action) {

    let newItem = Object.assign({}, editItem);
    try {
      if (action === 'Edit') {
        let stakeholder_id = newItem.stakeholder_id;
        let stakeholder_ref = newItem.stakeholder_name;

        const session = await Auth.currentSession();
        const apiUser = new User(session);
        newItem = getChanges(newItem, dataStakeholders, "stakeholder_id");
        var result = {}
        if (newItem !== null) {
          result = await apiUser.putItem(stakeholder_id, newItem, 'stakeholder');
        }

        if (result['errors']) {
          console.debug("PUT " + schemaName + " errors");
          console.debug(result['errors']);
          let errorsReturned = parsePUTResponseErrors(result['errors']);
          handleNotification({
            type: 'error',
            dismissible: true,
            header: "Update " + schemaName,
            content: (errorsReturned)
          });
        } else {
          if (newItem === null) {
            handleNotification({
              type: 'success',
              dismissible: true,
              header: "Nothing to Update for " + schemaName,
              content: "Nothing to Update for "  + stakeholder_ref,
            });
          } else {
            handleNotification({
              type: 'success',
              dismissible: true,
              header: "Update " + schemaName,
              content: stakeholder_ref + " updated successfully.",
            });
          }
          updateStakeholders();
          handleResetScreen();

          //This is needed to ensure the item in selectItems reflects new updates
          setSelectedItems([]);
          setFocusItem({});
        }
      }
      else {

        const session = await Auth.currentSession();
        const apiUser = new User(session);
        delete newItem.stakeholder_id;
        var result = await apiUser.postItem(newItem, 'stakeholder');

        if (result['errors']) {
          console.debug("PUT " + schemaName + " errors");
          console.debug(result['errors']);
          let errorsReturned = parsePUTResponseErrors(result['errors']);
          handleNotification({
            type: 'error',
            dismissible: true,
            header: "Add " + schemaName,
            content: (errorsReturned)
          });
          return false;
        } else {
          handleNotification({
            type: 'success',
            dismissible: true,
            header: "Add " + schemaName,
            content: newItem.stakeholder_name + " added successfully.",
          });
          updateStakeholders();
          handleResetScreen();
        }
      }

    } catch (e) {
      console.error(e);
      let response = '';
      if ('response' in e && 'data' in e.response) {
        //Check if errors key exists from Lambda errors.
        if (e.response.data.errors)
        {
          response = e.response.data.errors;
        } else if (e.response.data.cause){
          response = e.response.data.cause;
        } else {
          response = 'Unknown error occurred.';
        }
      } else {
        response =  'Unknown error occurred. It may be that there are no changes to save.';
      }

      handleNotification({
        type: 'error',
        dismissible: true,
        header: "Save " + schemaName,
        content: (response)
      });
    }
  }

  async function handleRefreshClick(e) {
    e.preventDefault();
    await updateStakeholders();
  }

  async function handleDeleteItemClick(e) {
    e.preventDefault();
    showDeleteConfirmaton();
  }

  async function handleDeleteItem(e) {
    e.preventDefault();

    await hideDeleteConfirmaton();

    let currentItem = 0;
    let multiReturnMessage = [];
    let notificationId;

    try {
      const session = await Auth.currentSession();
      const apiUser = new User(session);
      if(selectedItems.length > 1) {
        notificationId = handleNotification({
          type: 'success',
          loading: true,
          dismissible: false,
          header: "Deleting selected stakeholders..."
        });
      }
      for(let item in selectedItems) {
        currentItem = item;
        await apiUser.deleteStakeholder(selectedItems[item].stakeholder_id);
        //Combine notifications into a single message if multi selected used, to save user dismiss clicks.
        if(selectedItems.length > 1){
          multiReturnMessage.push(selectedItems[item].stakeholder_name);
        } else {
          handleNotification({
            type: 'success',
            dismissible: true,
            header: 'Stakeholders deleted successfully',
            content: selectedItems[item].stakeholder_name + ' was deleted.'
          });
        }

      }

      //Create notification where multi select was used.
      if(selectedItems.length > 1){
        handleNotification({
          id: notificationId,
          type: 'success',
          dismissible: true,
          header: 'Stakeholders deleted successfully',
          content: multiReturnMessage.join(", ") + ' were deleted.'
        });
      }


      //Unselect applications marked for deletion to clear apps.
      setSelectedItems([]);
      await updateStakeholders();

    } catch (e) {
      console.log(e);
        handleNotification({
            type: 'error',
            dismissible: true,
            header: 'Stakeholder deletion failed',
            content: selectedItems[currentItem].stakeholder_name + ' failed to delete.'
          });
    }
  }

  const ViewStakeholder = (props) => {

    const [selectedItemsViewer, lsetSelectedItemsViewer] = useState([]);

    if (selectedItems.length === 1) {

      return (
        <StakeholderView {...props}
            stakeholder={selectedItems[0]}
            app={{items: getCurrentStakeholderDetails(), isLoading: isLoadingApps, error: errorApps}}
            handleTabChange={handleViewerTabChange}
            dataAll={dataAll}
            selectedTab={viewerCurrentTab}/>
      );
    } else {
      return (null);
    }
  }

  useEffect( () => {
    let selected = [];

    if (!isLoadingStakeholders) {

      let item = dataStakeholders.filter(function (entry) {
        return entry[itemIDKey] === params.id;
      });

      if (item.length === 1) {
          selected.push(item[0]);
          handleItemSelectionChange(selected);
          //Check if URL contains edit path and switch to amend component.
          if (location.pathname && location.pathname.match('/edit/')) {
            handleEditItem(item[0]);
          } else if (location.pathname && location.pathname.match('/view/')){
            handleViewItem(item[0]);
          }
      } else if (location.pathname && location.pathname.match('/add')) {
        //Add url used, redirect to add screen.
        handleAddItem();
      }
    }

  }, [dataStakeholders]);

  //Update help tools panel.
  useEffect(() => {
    if (props.schema) {
      let tempContent = undefined;
      if (props.schema[schemaName].help_content) {
        tempContent = props.schema[schemaName].help_content;
        tempContent.header = props.schema[schemaName].friendly_name ? props.schema[schemaName].friendly_name : capitalize(schemaName);
      }
      props.setHelpPanelContent(tempContent)
    }
  }, [props.schema]);

  return (
    <div>
      {props.schemaIsLoading ?
        <StatusIndicator type="loading">
          Loading schema...
        </StatusIndicator>
        :!editingItem
          ?
          (
            <SpaceBetween direction="vertical" size="xs">
              <ItemTable
                sendNotification={handleNotification}
                schema={props.schema[schemaName]}
                schemaKeyAttribute={itemIDKey}
                schemaName={schemaName}
                dataAll={dataAll}
                items={dataStakeholders}
                selectedItems={selectedItems}
                handleSelectionChange={handleItemSelectionChange}
                isLoading={isLoadingStakeholders}
                errorLoading={errorStakeholders}
                handleRefreshClick={handleRefreshClick}
                handleAddItem={handleAddItem}
                handleViewItem={handleViewItem}
                handleDeleteItem={handleDeleteItemClick}
                handleEditItem={handleEditItem}
                handleDownloadItems={handleDownloadItems}
                userAccess={props.userEntityAccess}
                setHelpPanelContent={props.setHelpPanelContent}
                />
              <ViewStakeholder schema={props.schema}/>
            </SpaceBetween>
          )
          : viewItem ? 
          <ItemView action={action} schemaName={schemaName} schemas={props.schema} handleEditItem={handleEditItem} userAccess={props.userEntityAccess}  item={focusItem} handleSave={handleSave} handleCancel={handleResetScreen} updateNotification={handleNotification} setHelpPanelContent={props.setHelpPanelContent}/>
          
          : 
          <ItemAmend action={action} schemaName={schemaName} schemas={props.schema} userAccess={props.userEntityAccess}  item={focusItem} handleSave={handleSave} handleCancel={handleResetScreen} updateNotification={handleNotification} setHelpPanelContent={props.setHelpPanelContent}/>
        }
      <DeleteModal title={'Delete stakeholder'} onConfirmation={handleDeleteItem}>{selectedItems.length === 1 ? <p>Are you sure you wish to delete the selected stakeholder?</p> : <p>Are you sure you wish to delete the {selectedItems.length} selected stakeholders?</p>}</DeleteModal>
    </div>
  );
};
// Component TableView is a skeleton of a Table using AWS-UI React components.
export default UserStakeholderTable;
