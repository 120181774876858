import React, {useEffect, useState} from 'react';
import User from "../actions/user";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import { Auth } from "aws-amplify";
import {
  capitalize,
  exportTable,
  getChanges
} from '../resources/main.js'

import {
  SpaceBetween,
  StatusIndicator
} from '@awsui/components-react';

import ServerView from '../components/ServerView.jsx';
import { useMFApps } from "../actions/ApplicationsHook";
import { useGetServers } from "../actions/ServersHook.js";
import { useMFWaves } from "../actions/WavesHook.js";
import ItemTable from '../components/ItemTable.jsx';
import { useModal } from '../actions/Modal.js';
import {parsePUTResponseErrors} from "../resources/recordFunctions";
import MultipleItemAmend from "../components/MultipleItemAmend";
import ItemAmend from "../components/ItemAmend";
import ItemView from "../components/ItemView";

import * as XLSX from "xlsx";

const UserServerTable = (props) => {
  let location = useLocation()
  let navigate = useNavigate();
  let params = useParams();
  const [editingMultipleItems, setEditingMultipleItems] = useState(false);

  //Data items for viewer and table.
  const [{ isLoading: isLoadingApps, data: dataApps, error: errorApps }, { update: updateApps }] = useMFApps();
  const [{ isLoading: isLoadingServers, data: dataServers, error: errorServers }, { update: updateServers }] = useGetServers();
  const [{ isLoading: isLoadingWaves, data: dataWaves, error: errorWaves }, { update: updateWaves }] = useMFWaves();

  const dataAll = {application: {data: dataApps, isLoading: isLoadingApps, error: errorApps}, server: {data: dataServers, isLoading: isLoadingServers, error: errorServers}, wave: {data: dataWaves, isLoading: isLoadingWaves, error: errorWaves}};

  //Layout state management.
  const [editingItem, setEditingItem] = useState(false);
  const [viewItem, setViewItem] = useState(false);

  //Main table state management.
  const [selectedItems, setSelectedItems] = useState([]);
  const [focusItem, setFocusItem] = useState([]);

  //Viewer pane state management.
  const [viewerCurrentTab, setViewerCurrentTab] = useState('details');
  const [action, setAction] = useState(['Add']);

  //Get base path from the URL, all actions will use this base path.
  const basePath = location.pathname.split('/').length >= 2 ? '/' + location.pathname.split('/')[1] : '/';

  //Key for main item displayed in table.
  const itemIDKey = 'server_id';
  const schemaName = 'server';

  const { show: showDeleteConfirmaton, hide: hideDeleteConfirmaton, RenderModal: DeleteModal } = useModal()
  const { show: showUploadListConfirmaton, hide: hideUploadListConfirmaton, RenderModal: UploadListConfirmationModal } = useModal()
  const { show: showUploadListSuccess, hide: hideUploadListSuccess, RenderModal: UploadListSuccessModal } = useModal()
  const [uploadListSuccessMessage, setUploadListSuccessMessage] = useState("");

  const hiddenFileInput = React.createRef();

  function handleNotification(notification)
  {
    return props.updateNotification('add', notification)
  }

  function handleAddItem()
  {
    navigate({
      pathname: basePath + '/add'
    })
    setAction('Add')
    setFocusItem({});
    setEditingItem(true);
    setViewItem(false);

  }

  function handleDownloadItems()
  {
    if (selectedItems.length > 0 ) {
      // Download selected only.
      exportTable(selectedItems, "Servers", "servers")
    } else {
      //Download all.
      exportTable(dataServers, "Servers", "servers")
    }
  }

  function handleEditItem(selection = null)
  {
    if ( selectedItems.length === 1) {
      navigate({
        pathname: basePath + '/edit/' + selectedItems[0][itemIDKey]
      })
      setAction('Edit')
      setFocusItem(selectedItems[0]);
      setEditingItem(true);
      setViewItem(false);
    } else if ( selection ) {
      navigate({
        pathname: basePath + '/edit/' + selection[itemIDKey]
      })
      setAction('Edit');
      setFocusItem(selection);
      setEditingItem(true);
      setViewItem(false);
    }

  }

  function handleViewItem(selection = null)
  {
    if ( selectedItems.length === 1) {
      navigate({
        pathname: basePath + '/view/' + selectedItems[0][itemIDKey]
      })
      setAction('View')
      setFocusItem(selectedItems[0]);
      setEditingItem(true);
      setViewItem(true)
    } else if ( selection ) {
      navigate({
        pathname: basePath + '/view/' + selection[itemIDKey]
      })
      setAction('View');
      setFocusItem(selection);
      setEditingItem(true);
      setViewItem(true);
    }

  }

  async function handleViewerTabChange(tabselected)
  {
      setViewerCurrentTab(tabselected);
  }

  function handleResetScreen()
  {
    navigate({
      pathname: basePath
    })
    setEditingItem(false);
    setViewItem(false);
  }

  function handleItemSelectionChange(selection) {
    setSelectedItems(selection);
    if (selection.length === 1) {

      //TO-DO Need to pull in Waves or other data here.
      //updateApps(selection[0].app_id);

      setEditingMultipleItems(false)

    } else if (selection.length > 1 ) {
      setEditingMultipleItems(true)
    }
    //Reset URL to base table path.
    navigate({
      pathname: basePath
    })

  }

  const handleListUploadPopup = () => {
    hideUploadListConfirmaton();
    hiddenFileInput.current.click();
  };

  const handleListUploadSucess = () => {
    hideUploadListSuccess();
  }

  async function handleListUploadSelection(e){
  
    let selectedFile = e.target.files[0]
    let dataJson = []
    if (selectedFile.name.endsWith('.csv')) {

      let data = await readCSVFile(selectedFile);

      let csv = require('jquery-csv');

      dataJson = csv.toObjects(data);
    } else if (selectedFile.name.endsWith('.xlsx') || selectedFile.name.endsWith('.xls')) {
      let data = await readXLSXFile(selectedFile)
      let workbook = XLSX.read(data)
      let sheet = workbook.Sheets[workbook.SheetNames[0]];
      //Convert all numbers to text.
      Object.keys(sheet).forEach(function (s) {
        if (sheet[s].t === 'n') {
          delete sheet[s].w;
          sheet[s].z = '0';
          sheet[s].t = 's';
          sheet[s].w = sheet[s].v.toString()
          sheet[s].v = sheet[s].v.toString()
        }
      });

      dataJson = XLSX.utils.sheet_to_json(sheet)
    } else {
      //unsupported format of file.
      console.log(selectedFile.name + " - Unsupported file type.")
    }
    
    //based on the servers gathered from the uploaded spreadsheet
    //we filter our dataServers to select the correct servers
    
    var selectedItems = dataServers.filter((el) => {
      return dataJson.some((f) => {
        return el.server_name.toUpperCase() === f.server_name.toUpperCase()
      })
    })

    var notSelected = dataJson.filter((el) => {
      return !selectedItems.some((f) => {
        return el.server_name.toUpperCase() === f.server_name.toUpperCase()
      })
    })

    var string;
    if(notSelected.length === 0){
      string = "All servers were successfully located and have been selected for you."
    }else{
      string = `The following ${notSelected.length} servers were not identified based on the list you uploaded: `
      for (let i = 0; i < notSelected.length; i++){
        if(i === notSelected.length - 1){
          string += notSelected[i].server_name
          continue
        }
        string += notSelected[i].server_name + ", "
      }
    }

    handleItemSelectionChange(selectedItems)
    e.target.value = null;
    showUploadListSuccess();
    setUploadListSuccessMessage(string)
  }

  function readCSVFile(file){
    if (typeof (FileReader) != "undefined") {
        var reader = new FileReader();

        return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort();
          reject(new DOMException("Problem parsing input file."));
        };

        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsText(file);
      });
    } else {
        alert("This browser does not support HTML5.");
    }
  }

  function readXLSXFile(file){
    if (typeof (FileReader) != "undefined") {
      var reader = new FileReader();

      return new Promise((resolve, reject) => {
        reader.onerror = () => {
          reader.abort();
          reject(new DOMException("Problem parsing input file."));
        };

        reader.onload = () => {
          resolve(reader.result);
        };
        reader.readAsArrayBuffer(file);
      });
    } else {
      alert("This browser does not support HTML5.");
    }
  }

  function getCurrentServerApplication() {

    if (selectedItems.length === 1) {
      let apps = dataApps.filter(function (entry) {
        return entry.app_id === selectedItems[0].app_id;
      });

      if ( apps.length > 0){
        return apps;
      } else {
        return [];
      }
    }
  }

  async function handleSave(editItem, action, multi='false', appendComments=false) {

    let newItem = Object.assign({}, editItem);
    try {
      if (action === 'Edit') {
        let server_id = newItem.server_id;
        let server_ref = newItem.server_name;

        const session = await Auth.currentSession();
        const apiUser = new User(session);
        newItem = getChanges(newItem, dataServers, "server_id");
        var result = {}
        if (newItem !== null) {
          if (appendComments === true && 'comments' in editItem) {
            var serverDetails = dataServers.filter(server => server.server_id === server_id)
            if ('comments' in serverDetails[0]) {
              var oldComment = serverDetails[0]['comments']
              newItem['comments'] = oldComment + '\n' + newItem['comments'] 
            }
          }
          var result = await apiUser.putItem(server_id, newItem, 'server');
          
          if('app_id' in newItem){
            var app = dataApps.filter(app => app.app_id == newItem.app_id)
            var wave = dataWaves.filter(wave => wave.wave_id == app[0].wave_id)
            if(wave.length != 0){
              var updateServer = {
                "wave" : wave[0].wave_name,
                "automation_wave" : wave[0].wave_name
              }
              await apiUser.putItem(server_id, updateServer, 'server');
            }
            else{
              var updateServer = {
                "wave" : "",
                "automation_wave" : ""
              }
              await apiUser.putItem(server_id, updateServer, 'server');
            }
          }
        }

        if (result['errors']) {
          console.debug("PUT " + schemaName + " errors");
          console.debug(result['errors']);
          let errorsReturned = parsePUTResponseErrors(result['errors']);
          handleNotification({
            type: 'error',
            dismissible: true,
            header: "Update " + schemaName,
            content: (errorsReturned)
          });
        } else {
          if (multi === 'false') {
            if (newItem === null) {
              handleNotification({
                type: 'success',
                dismissible: true,
                header: "Nothing to Update for "  + schemaName,
                content: "Nothing to update for " + server_ref,
              });
            } else {
              
              handleNotification({
                type: 'success',
                dismissible: true,
                header: "Update " + schemaName,
                content: server_ref + " updated successfully.",
              });
          }
          } else if (multi === 'multi-edit-finished') {
            handleNotification({
              type: 'success',
              dismissible: true,
              header: "Update Multiple " + schemaName,
              content: 'Multi Edit updated successfully.',
            });
          }
         if (multi === 'false' || multi === 'multi-edit-finished') {
          updateServers();
          handleResetScreen();
          
          
          //This is needed to ensure the item in selectItems reflects new updates
          setSelectedItems([]);
          setFocusItem({});
        } 
        }
      }
      else {

        const session = await Auth.currentSession();
        const apiUser = new User(session);
        delete newItem.server_id;
        var result = await apiUser.postItem(newItem, 'server');
        
        if('app_id' in result['newItems'][0]){
          var app = dataApps.filter(app => app.app_id == newItem.app_id)
          var wave = dataWaves.filter(wave => wave.wave_id == app[0].wave_id)
          if(wave.length != 0){
            var updateServer = {
              "wave" : wave[0].wave_name,
              "automation_wave" : wave[0].wave_name
            }
            await apiUser.putItem(result['newItems'][0].server_id, updateServer, 'server');
          }
          else{
            var updateServer = {
              "wave" : "",
              "automation_wave" : ""
            }
            await apiUser.putItem(result['newItems'][0].server_id, updateServer, 'server');
          }
        }

        if (result['errors']) {
          console.debug("PUT " + schemaName + " errors");
          console.debug(result['errors']);
          let errorsReturned = parsePUTResponseErrors(result['errors']);
          handleNotification({
            type: 'error',
            dismissible: true,
            header: "Add " + schemaName,
            content: (errorsReturned)
          });
          return false;
        } else {
          handleNotification({
            type: 'success',
            dismissible: true,
            header: "Add " + schemaName,
            content: newItem.server_name + " added successfully.",
          });
          updateServers();
          handleResetScreen();
        }
      }

    } catch (e) {
      console.error(e);
      let response = '';
      if ('response' in e && 'data' in e.response) {
        //Check if errors key exists from Lambda errors.
        if (e.response.data.errors)
        {
          response = e.response.data.errors;
        } else if (e.response.data.cause){
          response = e.response.data.cause;
        } else {
          response = 'Unknown error occurred.';
        }
      } else {
        response =  'Unknown error occurred. It may be that there are no changes to save.';
      }

      handleNotification({
        type: 'error',
        dismissible: true,
        header: "Save " + schemaName,
        content: (response)
      });
    }
  }

  async function handleRefreshClick(e) {
    e.preventDefault();
    await updateServers();
  }

  async function handleDeleteItemClick(e) {
    e.preventDefault();
    showDeleteConfirmaton();
  }

  async function handleListUploadClick(e) {
    e.preventDefault();
    showUploadListConfirmaton();
  }

  async function handleDeleteItem(e) {
    e.preventDefault();

    await hideDeleteConfirmaton();

    let currentItem = 0;
    let multiReturnMessage = [];
    let notificationId;

    try {
      const session = await Auth.currentSession();
      const apiUser = new User(session);
      if(selectedItems.length > 1) {
        notificationId = handleNotification({
          type: 'success',
          loading: true,
          dismissible: false,
          header: "Deleting selected servers..."
        });
      }
      for(let item in selectedItems) {
        currentItem = item;
        await apiUser.deleteServer(selectedItems[item].server_id);
        //Combine notifications into a single message if multi selected used, to save user dismiss clicks.
        if(selectedItems.length > 1){
          multiReturnMessage.push(selectedItems[item].server_name);
        } else {
          handleNotification({
            type: 'success',
            dismissible: true,
            header: 'Server deleted successfully',
            content: selectedItems[item].server_name + ' was deleted.'
          });
        }

      }

      //Create notification where multi select was used.
      if(selectedItems.length > 1){
        handleNotification({
          id: notificationId,
          type: 'success',
          dismissible: true,
          header: 'Servers deleted successfully',
          content: multiReturnMessage.join(", ") + ' were deleted.'
        });
      }


      //Unselect applications marked for deletion to clear apps.
      setSelectedItems([]);
      await updateServers();

    } catch (e) {
      console.log(e);
        handleNotification({
            type: 'error',
            dismissible: true,
            header: 'Server deletion failed',
            content: selectedItems[currentItem].server_name + ' failed to delete.'
          });
    }
  }

  const ViewServer = (props) => {

    const [selectedItemsViewer, lsetSelectedItemsViewer] = useState([]);

    if (selectedItems.length === 1) {

      return (
        <ServerView {...props}
            server={selectedItems[0]}
            app={{items: getCurrentServerApplication(), isLoading: isLoadingApps, error: errorApps}}
            handleTabChange={handleViewerTabChange}
            dataAll={dataAll}
            selectedTab={viewerCurrentTab}/>
      );
    } else {
      return (null);
    }
  }

  useEffect( () => {
    let selected = [];

    if (!isLoadingServers) {

      let item = dataServers.filter(function (entry) {
        return entry[itemIDKey] === params.id;
      });

      if (item.length === 1) {
          selected.push(item[0]);
          handleItemSelectionChange(selected);
          //Check if URL contains edit path and switch to amend component.
          if (location.pathname && location.pathname.match('/edit/')) {
            handleEditItem(item[0]);
          } else if (location.pathname && location.pathname.match('/view/')){
            handleViewItem(item[0]);
          }
      } else if (location.pathname && location.pathname.match('/add')) {
        //Add url used, redirect to add screen.
        handleAddItem();
      }
    }

  }, [dataServers]);

  //Update help tools panel.
  useEffect(() => {
    if (props.schema) {
      let tempContent = undefined;
      if (props.schema[schemaName].help_content) {
        tempContent = props.schema[schemaName].help_content;
        tempContent.header = props.schema[schemaName].friendly_name ? props.schema[schemaName].friendly_name : capitalize(schemaName);
      }
      props.setHelpPanelContent(tempContent)
    }
  }, [props.schema]);

  return (
    <div>
      {props.schemaIsLoading ?
        <StatusIndicator type="loading">
          Loading schema...
        </StatusIndicator>
        :!editingItem
          ?
          (
            <SpaceBetween direction="vertical" size="xs">
              <ItemTable
                sendNotification={handleNotification}
                schema={props.schema[schemaName]}
                schemaKeyAttribute={itemIDKey}
                schemaName={schemaName}
                dataAll={dataAll}
                items={dataServers}
                selectedItems={selectedItems}
                handleSelectionChange={handleItemSelectionChange}
                isLoading={isLoadingServers}
                errorLoading={errorServers}
                handleRefreshClick={handleRefreshClick}
                handleAddItem={handleAddItem}
                handleDeleteItem={handleDeleteItemClick}
                handleEditItem={handleEditItem}
                handleViewItem={handleViewItem}
                handleDownloadItems={handleDownloadItems}
                userAccess={props.userEntityAccess}
                setHelpPanelContent={props.setHelpPanelContent}
                handleListUpload = {handleListUploadClick}
                />
              <ViewServer schema={props.schema}/>
            </SpaceBetween>
          )
          : viewItem ? 
          <ItemView action={action} schemaName={schemaName} schemas={props.schema} handleEditItem={handleEditItem} userAccess={props.userEntityAccess}  item={focusItem} handleSave={handleSave} handleCancel={handleResetScreen} updateNotification={handleNotification} setHelpPanelContent={props.setHelpPanelContent}/>
          :
          (editingMultipleItems ? 
            <MultipleItemAmend action={action} selection={selectedItems} schemaName={schemaName} schemas={props.schema} userAccess={props.userEntityAccess} item={focusItem} handleSave={handleSave} handleCancel={handleResetScreen} updateNotification={handleNotification} setHelpPanelContent={props.setHelpPanelContent}/>
            :
            <ItemAmend action={action} schemaName={schemaName} schemas={props.schema} userAccess={props.userEntityAccess}  item={focusItem} handleSave={handleSave} handleCancel={handleResetScreen} updateNotification={handleNotification} setHelpPanelContent={props.setHelpPanelContent}/>
          )
        }
      <DeleteModal title={'Delete servers'} onConfirmation={handleDeleteItem}>{selectedItems.length === 1 ? <p>Are you sure you wish to delete the selected server?</p> : <p>Are you sure you wish to delete the {selectedItems.length} selected servers?</p>}</DeleteModal>
      <input ref={hiddenFileInput} accept=".csv,.xlsx" type="file" name="file" onChange={handleListUploadSelection} style={{ display: 'none' }}/>
      <UploadListConfirmationModal title={'Upload Server List'} onConfirmation={handleListUploadPopup}>{<p>Ensure you upload a spreadsheet where the column is marked "server_name" and the values underneath are the servers you want to select. Servers can be written in lower or upper case as this is a case insensitive selection tool.</p>} </UploadListConfirmationModal>
      <UploadListSuccessModal title={'Server List Uploaded'} noCancel={true} onConfirmation={handleListUploadSucess}>{<p>{uploadListSuccessMessage}</p>}</UploadListSuccessModal>
    </div>
  );
};
// Component TableView is a skeleton of a Table using AWS-UI React components.
export default UserServerTable;
