import {
  Header,
  SpaceBetween,
  Button,
  ButtonDropdown,
  Modal,
  Box,
  Container,
  Form,
  FormField,
  Input,
  ColumnLayout,
  Badge,
  Pagination
} from '@awsui/components-react';

import React, {useEffect, useState} from "react";
import { propExists } from '../resources/main';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from '@mui/material';

// Table header content, shows how many items are selected and contains the action stripe
const TableHeader = ({title, description, selectedItems, counter, handleRefreshClick, handleViewClick, handleDeleteClick, handleEditClick, handleAddClick, handleActionSelection, actionItems, handleDownload, actionsButtonDisabled, disabledButtons, info, handleSelectionClear, setFavourite, schemaName, queryDisabled, filteringProperties, handleUploadListClick, uploadListSelection}) => {
  const isOnlyOneSelected = selectedItems ? selectedItems.length === 1 : false;
  const howManySelected = selectedItems ? selectedItems.length : 0;
  
  const locaStorageKeys = {
    favQueries: schemaName + "_favourite_queries",
    tableQuery: schemaName + "_query"
  }

  // const [savedQueries, setSavedQueries] = useState([])
  // localStorage.setItem(locaStorageKeys.favQueries, '[]')

  const [savedQueries, setSavedQueries] = useState(localStorage[locaStorageKeys.favQueries] ? JSON.parse(localStorage.getItem(locaStorageKeys.favQueries)) : [])

  const [clearVisible, setClearVisible] = React.useState(false);
  const [saveQueryModal, setSaveQuery] = React.useState(false);
  const [queryName, setQueryName] = useState('')

  const [
    currentPageIndex,
    setCurrentPageIndex
  ] = React.useState(1);

  const [paginatedArray, setPaginatedArray] = useState([])
  function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  }

  useEffect(() => {
    setPaginatedArray(paginate(savedQueries, 5, currentPageIndex))
  },[currentPageIndex])

  const handleClickSaveQuery = () => {
    setSaveQuery(true);
  };

  const handleSaveQueryClose = () => {
    setSaveQuery(false);
  };

  const handleClickClearOpen = () => {
    setClearVisible(true);
  };

  const handleClearClose = () => {
    setClearVisible(false);
  };

  
  function deleteQuery(index) {
    var tempArray = savedQueries.filter(query => query.id != index)
    setSavedQueries(tempArray)
    localStorage.setItem(locaStorageKeys.favQueries, JSON.stringify(tempArray))
  }

  useEffect(() => {
    setPaginatedArray(paginate(savedQueries, 5, currentPageIndex))
  },[savedQueries])

  function saveQuery() {
    var tempArray = savedQueries
    var interimQuery = JSON.parse(localStorage[locaStorageKeys.tableQuery])
    interimQuery['id'] = uuidv4()
    interimQuery['name'] = queryName
    tempArray.push(interimQuery)
    setSavedQueries(tempArray)
    localStorage.setItem(locaStorageKeys.favQueries, JSON.stringify(tempArray))
    setSavedQueries(JSON.parse(localStorage.getItem(locaStorageKeys.favQueries)))
    handleSaveQueryClose()
  }

  // const disableActionsButton = actionsButtonDisabled !== undefined ? actionsButtonDisabled : !isOnlyOneSelected
  const adjustedTitle = title == 'Stakeholdermanagements' ? 'Stakeholder Management' : title
  const enableMultiSelect = (adjustedTitle !== 'Waves' & adjustedTitle !== 'Stakeholders' & adjustedTitle != 'Stakeholder Management') ? true : false

  return (
    <>
    <Header
      variant="h2"
      counter={counter ? counter : undefined}
      description={description ? description : undefined}
      info={info}
      actions={
        <SpaceBetween direction="horizontal" size="s">
          {handleRefreshClick ? <Button disabled={queryDisabled} onClick={() => {handleClickSaveQuery()}}>Save Filter</Button> : null}
          {handleRefreshClick ? <Button variant='primary' onClick={() => {handleClickClearOpen()}}>Saved Filters</Button> : null}
          {handleUploadListClick ? <Button onClick={handleUploadListClick} iconName="upload"> Upload File</Button> : null}
          {handleRefreshClick ? <Button disabled={!howManySelected} onClick={() => {handleSelectionClear([])}}>Clear Selection</Button> : null}
          {handleRefreshClick ? <Button onClick={handleRefreshClick} iconAlign="right" iconName="refresh"/> : null}
          {handleViewClick ?  <Button onClick={handleViewClick} disabled={!isOnlyOneSelected}> View</Button>: null}
          {!(enableMultiSelect & howManySelected > 1) ? handleEditClick ? <Button onClick={handleEditClick} disabled={!isOnlyOneSelected || (disabledButtons ? disabledButtons.edit ? disabledButtons.edit : false : false)}> Edit</Button> : null
            :
          (handleEditClick ? <Button onClick={handleEditClick} disabled={(disabledButtons ? disabledButtons.edit ? disabledButtons.edit : false : false)}>Multiple Edit</Button> : null)}
          {handleDeleteClick ? <Button onClick={handleDeleteClick} disabled={selectedItems.length === 0  || (disabledButtons ? disabledButtons.delete ? disabledButtons.delete: false : false)}> Delete</Button> : null}
          {handleAddClick ? <Button onClick={handleAddClick} disabled={disabledButtons ? disabledButtons.add ? disabledButtons.add : false : false} variant="primary"> Add</Button> : null}
          {handleDownload ? <Button onClick={handleDownload} iconName="download" variant="icon"/> : null}
        </SpaceBetween>
      }>
      {adjustedTitle}
    </Header>
    {/* MODAL for Saved Filters */}
    <Modal
    
      onDismiss={() => setClearVisible(false)}
      visible={clearVisible}
      closeAriaLabel="Close modal"
      footer={
        <Typography variant="h6">Saved Filters only exist on this device
        </Typography>
      }
      header="Saved Filters">
        <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
      {savedQueries.length === 0 ? 'No Saved Filters' : ''}
      </div>
      <>
       
      {paginatedArray.map((query, key)=> {
        return <Container key={key}>
                  <SpaceBetween direction="vertical" size='xs'>
                    <ColumnLayout columns={2}>
                      <SpaceBetween direction="vertical" size='xs'>
                        <b>{query.name}</b>
                      </SpaceBetween>
                      <SpaceBetween direction="horizontal" size='xs'>
                        <Button onClick={() => {setFavourite(query)}} variant="primary">Use Filter</Button>
                        <Button onClick={() => {deleteQuery(query.id)}} variant="secondary">Delete</Button>
                      </SpaceBetween>
                    </ColumnLayout>
                    {query.tokens.map((token, tokenKey) => {
                      if ('propertyKey' in token) {
                        var attrName = filteringProperties.filter((property) => property.key === token.propertyKey)[0]['propertyLabel']
                        if (tokenKey === query.tokens.length - 1) {
                          return attrName +  ' ' + token.operator + ' ' +  token.value
                        }
                        return <div>
                        {attrName +  ' ' + token.operator + ' ' +  token.value + ' '} 
                        <Badge>{query.operation.toUpperCase()}</Badge>
                        </div>
                      } else {
                        if (tokenKey === query.tokens.length - 1) {
                          return  token.value
                        }
                        return <div>
                          {token.value + ' '}
                          <Badge>{query.operation.toUpperCase()}</Badge>
                        </div>
                        
                      
                      }
                      })}
                  </SpaceBetween>
              </Container> 
      })}
      <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    }}>
       <Pagination
        ariaLabels={{
          nextPageLabel: "Next page",
          previousPageLabel: "Previous page",
          pageLabel: pageNumber =>
            `Page ${pageNumber} of all pages`
        }}
        currentPageIndex={currentPageIndex}
        onChange={({ detail }) =>
          setCurrentPageIndex(detail.currentPageIndex)
        }
     
        pagesCount={Math.ceil(savedQueries.length/5)}
      />
       </div>
      </>
    </Modal>
    {/* MODAL for Saving Queries */}
    <Modal
      onDismiss={() => setSaveQuery(false)}
      visible={saveQueryModal}
      header="Save Filter"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={handleSaveQueryClose} variant="link">Cancel</Button>
            <Button disabled={!queryName.length} onClick={saveQuery} variant="primary">Save</Button>
          </SpaceBetween>
        </Box>
      }>
          <SpaceBetween direction="vertical" size="l">
            <FormField label="Filter Name">
              <Input 
                value={queryName}
                onChange={({ detail: { value } }) => setQueryName(value)}
              />
            </FormField>
            
          </SpaceBetween>

    </Modal>
    </>
  );
};

export default TableHeader;
